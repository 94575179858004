import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  Tabs,
  Tab,
  useMediaQuery,
  Card,
  FormControl,
  Input,
  Select,
  MenuItem,
  SvgIcon,
  Stack,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import ReactImageMagnify from "react-image-magnify";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./CartModal.scss";
import { Link, useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as queries from "../../../operations/queries";
import * as mutations from "../../../operations/mutations";
import {
  CountryContext,
  mainContext,
} from "../../../container/buyer/MainComponent";
import { useTranslation } from "react-i18next";
import theme from "../../../theme";
import PreOrderImageEN from "../../../NewAssets/image/pre_order_en.png";
import PreOrderImageAR from "../../../NewAssets/image/pre_order_ar.png";
import PlusMinusImage from "../../../NewAssets/image/plus-minus.png";
import TabbyProductWidget from "../../../container/buyer/Innerpages/postpayStrip/tabbyProduct";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { toast } from "react-toastify";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
// import DetailsPageSkelton from "../../skelton/DetailsPageSkelton";
interface ModalProps {
  open: boolean;
  setCartModalOpen: any;
  urlkey: any;
  productdata: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  detailsData: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, detailsData, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        position: "relative",
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      {detailsData?.top_badge_left && (
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            zIndex: 10,
          }}
        >
          <img
            src={detailsData?.top_badge_left}
            alt={detailsData?.top_badge_left_title || "Badge"}
            style={{
              width: "120px",
              height: "auto",
              marginTop: "-35px",
            }}
            loading="lazy"
          />
        </div>
      )}

      {/* Content of TabPanel */}
      {value === index && (
        <Box sx={{ p: 3, flex: 1 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const detailsSlider = {
  mobileSlider: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const qtyOptions = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: "custom", label: "Custom" },
];

function CustomSelectIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.2825 7.28294C13.3528 7.21322 13.4086 7.13027 13.4467 7.03888C13.4848 6.94748 13.5044 6.84945 13.5044 6.75044C13.5044 6.65143 13.4848 6.5534 13.4467 6.46201C13.4086 6.37062 13.3528 6.28766 13.2825 6.21794L9.53255 2.46794C9.46282 2.39765 9.37987 2.34185 9.28848 2.30377C9.19708 2.2657 9.09905 2.24609 9.00005 2.24609C8.90104 2.24609 8.80301 2.2657 8.71161 2.30377C8.62022 2.34185 8.53727 2.39765 8.46755 2.46794L4.71755 6.21794C4.64725 6.28766 4.59145 6.37062 4.55338 6.46201C4.5153 6.5534 4.4957 6.65143 4.4957 6.75044C4.4957 6.84945 4.5153 6.94748 4.55338 7.03887C4.59145 7.13027 4.64725 7.21322 4.71755 7.28294C4.78727 7.35324 4.87022 7.40903 4.96161 7.44711C5.05301 7.48519 5.15104 7.50479 5.25005 7.50479C5.34905 7.50479 5.44708 7.48519 5.53848 7.44711C5.62987 7.40903 5.71282 7.35324 5.78254 7.28294L9.00005 4.05794L12.2175 7.28294C12.2873 7.35324 12.3702 7.40903 12.4616 7.44711C12.553 7.48519 12.651 7.50479 12.75 7.50479C12.8491 7.50479 12.9471 7.48519 13.0385 7.44711C13.1299 7.40903 13.2128 7.35324 13.2825 7.28294ZM9.53255 15.5329L13.2825 11.7829C13.3525 11.713 13.4079 11.63 13.4458 11.5386C13.4836 11.4473 13.5031 11.3493 13.5031 11.2504C13.5031 11.0507 13.4238 10.8592 13.2825 10.7179C13.2126 10.648 13.1296 10.5925 13.0382 10.5547C12.9469 10.5169 12.8489 10.4974 12.75 10.4974C12.5503 10.4974 12.3588 10.5767 12.2175 10.7179L9.00005 13.9429L5.78254 10.7179C5.71282 10.6476 5.62987 10.5919 5.53848 10.5538C5.44708 10.5157 5.34905 10.4961 5.25005 10.4961C5.15104 10.4961 5.05301 10.5157 4.96161 10.5538C4.87022 10.5919 4.78727 10.6476 4.71755 10.7179C4.64725 10.7877 4.59145 10.8706 4.55338 10.962C4.5153 11.0534 4.4957 11.1514 4.4957 11.2504C4.4957 11.3495 4.5153 11.4475 4.55338 11.5389C4.59145 11.6303 4.64725 11.7132 4.71755 11.7829L8.46755 15.5329C8.53727 15.6032 8.62022 15.659 8.71161 15.6971C8.80301 15.7352 8.90104 15.7548 9.00005 15.7548C9.09905 15.7548 9.19708 15.7352 9.28848 15.6971C9.37987 15.659 9.46282 15.6032 9.53255 15.5329Z"
        fill="#000"
        fillOpacity="0.51"
      />
    </SvgIcon>
  );
}

const ProductModal: React.FC<ModalProps> = ({
  open,
  urlkey,
  setCartModalOpen,
  productdata,
}) => {
  const [rvalue, setRtabValue] = React.useState(0);
  const [lvalue, setLtabValue] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [preOrder, setPreOrder] = useState(false);
  const [addingToCart, setAddingToCart] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);
  const [proQty, setProQty] = React.useState("1");
  const [currentCartId, setCurrentCartId] = useState();
  const [currentStore, setCurrentStore] = useState();
  const [showCustomInput, setShowCustomInput] = useState<boolean>(false);
  const [preOrderImg, setPreOrderImg] = useState(PreOrderImageEN);
  const [displayWasPrice, setDisplayWasPrice] = useState(false);
  const [preOrderDeliveryDate, setPreOrderDeliveryDate] = useState("");
  const [wishlistProductId, setwishlistProductId] = useState();
  const [selectedGrinder, setSelectedGrinder] = useState<string>("");
  const [bagSizes, setBagSizes] = useState<string[]>([]);
  const [selectedBagSize, setSelectedBagSize] = useState<string>("");
  const [currentVariant, setCurrentVariant] = useState<any>(null);
  const [regularPrice, setRegularPrice] = useState(null);
  const [sellingPrice, setSellingPrice] = useState(null);
  const navigate = useNavigate();
  const wishlistId = localStorage.getItem("wishlistId");
  const loggedInToken = localStorage.getItem("loggedInCust");
  const userLoggedIn = localStorage.getItem("LoggedInUser");
  const { store, country } = useContext(CountryContext);
  const {
    setCartData,
    setCartCount,
    quest_cart_id,
    set_quest_cart_id,
    setCartId,
    wishlistData,
    setIsAdded,
    isAdded,
  } = useContext(mainContext);
  const { t } = useTranslation();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let galleryImage: any = [];
  let smallImage: any = [];
  let colorVariation: any = "";
  let lableVariation: any = "";
  let cartId: any = quest_cart_id || "";
  let variantsDetails: any = "";
  const detailsData = productdata;
  const [tempCartId, setTempCartId] = React.useState(cartId);
  // const  getCartId  = "2";

  let storedDataString: string | null = sessionStorage.getItem("storeAndCart");

  let storeAndCartData: any[] = storedDataString
    ? JSON.parse(storedDataString)
    : [];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setRtabValue(newValue);
  };
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setLtabValue(newValue);
  };

  const handleViewMore = () => {
    setShowAll(!showAll);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handledQtyChange = (event: any) => {
    const selectedValue = event.target.value as string;
    if (selectedValue === "custom") {
      setShowCustomInput(true);
      setProQty("");
    } else {
      setShowCustomInput(false);
      setProQty(selectedValue);
    }
  };

  const [fetchQueryId, { data: custQueryDataRes }] = useLazyQuery(
    queries.customerQueries.GET_CUSTOMER_CART_QUERY,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  useEffect(() => {
    if (!loggedInToken && userLoggedIn !== null) {
      fetchQueryId();
    }
  }, [loggedInToken, userLoggedIn, fetchQueryId]);

  useEffect(() => {
    if (
      wishlistData?.some(
        (item: any) => item.product.url_key === productdata?.url_key
      )
    ) {
      setIsAdded(true);
    } else {
      setIsAdded(false);
    }
    //eslint-disable-next-line
  }, [detailsData, wishlistData]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    wishlistData?.filter((item: any) => {
      if (item?.product?.url_key === productdata?.url_key) {
        setwishlistProductId(item?.id);
      } else {
        console.log("No matched item found");
      }
    });
  }, [wishlistData, productdata]);

  const description = detailsData?.short_description?.html;
  const displayedData = showAll
    ? detailsData?.attributes
    : detailsData?.attributes?.slice(0, 4);

  const variants = detailsData?.variants || [];

  const groupedVariants = variants?.reduce((acc: any, variant: any) => {
    const grinderType = variant?.attributes?.find(
      (attr: any) => attr.code === "grinder_type"
    )?.label;

    const bagSize = variant?.attributes?.find(
      (attr: any) => attr.code === "bag_size"
    )?.label;

    if (grinderType && bagSize) {
      if (!acc[grinderType]) acc[grinderType] = [];
      if (!acc[grinderType].includes(bagSize)) acc[grinderType].push(bagSize);
    }
    return acc;
  }, {});

  useEffect(() => {
    const initialGrinderType = Object.keys(groupedVariants)?.[0] || "";
    if (initialGrinderType !== selectedGrinder) {
      setSelectedGrinder(initialGrinderType);
    }

    const initialBagSizes = groupedVariants[initialGrinderType] || [];
    if (JSON.stringify(initialBagSizes) !== JSON.stringify(bagSizes)) {
      setBagSizes(initialBagSizes);
    }

    const lastBagSize = initialBagSizes[initialBagSizes?.length - 1] || "";
    if (lastBagSize !== selectedBagSize) {
      setSelectedBagSize(lastBagSize);
    }
    // eslint-disable-next-line
  }, [variants]);

 useEffect(() => {
    const currentBagSizes = groupedVariants[selectedGrinder] || [];
    setBagSizes(currentBagSizes);
    const lastBagSize = currentBagSizes[currentBagSizes?.length - 1] || "";
    setSelectedBagSize(lastBagSize);
    //eslint-disable-next-line
  }, [selectedGrinder]);

  useEffect(() => {
    const variant = detailsData?.variants?.find((variant: any) => {
      const grinderType = variant.attributes.find(
        (attr: any) => attr.code === "grinder_type"
      )?.label;

      const bagSize = variant.attributes.find(
        (attr: any) => attr.code === "bag_size"
      )?.label;

      return grinderType === selectedGrinder && bagSize === selectedBagSize;
    });

    setCurrentVariant(variant || null);
    //eslint-disable-next-line
  }, [selectedGrinder, selectedBagSize, currentVariant]);

  const handleGrinderChange = (event: any) => {
    setSelectedGrinder(event.target.value);
  };

  const handleBagSizeClick = (size: string) => {
    setSelectedBagSize(size);
  };

  // eslint-disable-next-line
  let preOrderCheck = detailsData?.attributes.map((row: any, index: any) =>
    row?.code === "pre_order_delivery_date" ? setPreOrder(true) : ""
  );

  if (groupedVariants && Object.keys(groupedVariants).length > 0) {
    variantsDetails = currentVariant?.product;
  } else if (detailsData?.variants && detailsData?.variants.length > 0) {
    variantsDetails = detailsData?.variants[lvalue]?.product;
    colorVariation = detailsData?.variants[lvalue]?.attributes[0]?.label;
    lableVariation = detailsData?.variants[lvalue]?.attributes[0]?.code;
  }

  useEffect(() => {
    const newRegularPrice =
      variantsDetails?.price_range?.minimum_price?.regular_price?.value ||
      detailsData?.price_range?.minimum_price?.regular_price?.value;
    const newSellingPrice =
      variantsDetails?.price_range?.minimum_price?.final_price?.value ||
      detailsData?.price_range?.minimum_price?.final_price?.value;

    setRegularPrice(newRegularPrice);
    setSellingPrice(newSellingPrice);
  }, [variantsDetails, detailsData]);

  const AmountOff =
    regularPrice && sellingPrice
      ? ((regularPrice - sellingPrice) / regularPrice) * 100
      : 0;
  const AmountOffFormatted = Math.round(AmountOff);

  let childSkuName =
    variantsDetails && variantsDetails !== undefined
      ? variantsDetails?.sku
      : detailsData?.sku;

  const sellerData = productdata;

  const { data: cartDetailsRes, refetch: getCartRefetch } = useQuery(
    queries.cartQueries.GET_CART_DETAILS,
    {
      skip: !quest_cart_id || quest_cart_id === "" || quest_cart_id === null,
      variables: { cart_id: quest_cart_id },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const [loggedUserCart] = useMutation(
    mutations.addToCartMutation.LOGGUSER_CART_DATA,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const [addConfigurableProductsToCart] = useMutation(
    mutations.addToCartMutation.ADD_CONFIG_PRODUCTS_TO_CART_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const [addProductsToCart] = useMutation(
    mutations.addToCartMutation.ADD_SIMPLE_CART_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const [guestCart] = useMutation(mutations.addToCartMutation.GUEST_CART_DATA, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const handleConfigProdCart = async (finalisedSellerId: any) => {
    return new Promise(async (resolve) => {
      try {
        await addConfigurableProductsToCart({
          variables: {
            input: {
              cart_id: cartId,
              cart_items: [
                {
                  parent_sku: detailsData?.sku,
                  data: {
                    quantity: proQty,
                    sku: childSkuName,
                    seller_id: finalisedSellerId,
                  },
                },
              ],
            },
          },
          context: {
            headers: {
              Authorization: loggedInToken
                ? `Bearer ${JSON.parse(loggedInToken)}`
                : "",
              Connection: "keep-alive",
              store: store,
            },
          },
        }).then((res: any) => {
          setAddedToCart(true);
        });
        resolve(true);
        setCartId(cartId);
      } catch (error) {
        resolve(false);
        console.error(error);
      }
    });
  };

  const [addProductsToWishlist] = useMutation(
    mutations.addProductToWishlist.ADD_PRODUCTS_TO_WISHLIST,
    {
      onCompleted: () => {
        toast.success(t("inner_pages.detail_page.wishlist_sucs_toast"));
        setIsAdded(true);
      },
      onError: (err) => {
        toast.error(t("inner_pages.detail_page.wishlist_err_toast"));
      },
    }
  );

  const handleWishlistClick = () => {
    if (!loggedInToken) {
      toast.warning(t("inner_pages.detail_page.login_toast"));
      return;
    }

    if (wishlistId) {
      if (cartId) {
        if (detailsData?.variants && detailsData?.variants.length > 0) {
          addProductsToWishlist({
            variables: {
              wishlistId: parseInt(wishlistId),
              wishlistItems: [
                {
                  parent_sku: productdata?.sku,
                  sku: childSkuName,
                  quantity: parseInt(proQty),
                },
              ],
            },
            context: {
              headers: {
                Authorization: loggedInToken
                  ? `Bearer ${JSON.parse(loggedInToken)}`
                  : "",
                Connection: "keep-alive",
                store: store,
              },
            },
          });
        } else {
          addProductsToWishlist({
            variables: {
              wishlistId: parseInt(wishlistId),
              wishlistItems: [
                {
                  sku: productdata?.sku,
                  quantity: parseInt(proQty),
                },
              ],
            },
            context: {
              headers: {
                Authorization: loggedInToken
                  ? `Bearer ${JSON.parse(loggedInToken)}`
                  : "",
                Connection: "keep-alive",
                store: store,
              },
            },
          });
        }
      }
    } else {
      toast.error(t("inner_pages.detail_page.no_wishlist_toast"));
    }
  };

  const [removeProductsToWishlist] = useMutation(
    mutations.addProductToWishlist.REMOVE_PRODUCTS_FROM_WISHLIST,
    {
      onCompleted: () => {
        toast.success(t("inner_pages.detail_page.product_removed_toast"));
        setIsAdded(false);
      },
      onError: (err) => {
        toast.error(t("inner_pages.detail_page.product_removed_toast_err"));
      },
    }
  );

  const handleRemoveWishlistClick = () => {
    const parsedWishlistId = wishlistId ? parseInt(wishlistId, 10) : NaN;

    if (wishlistId) {
      removeProductsToWishlist({
        variables: {
          wishlistId: parsedWishlistId,
          wishlistItemsIds: wishlistProductId
            ? [parseInt(wishlistProductId)]
            : "",
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store,
          },
        },
      });
    } else {
      toast.error("No wishlist found. Please try again.");
    }
  };

  const addSimpleCart = (finalisedSellerId: any) => {
    return new Promise((resolve) => {
      addProductsToCart({
        variables: {
          // cartId: 'AdGvg2DjN1YedY5ZgoyIQvBJ8xHs0Ptq',
          cartId: cartId,
          quantity: proQty,
          sku: detailsData?.sku,
          seller_id: finalisedSellerId,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store,
          },
        },
      })
        .then((response) => {
          setCartId(cartId);
          resolve(true);
          setAddedToCart(true);
        })
        .catch((error) => {
          console.error(error);
          resolve(false);
        });
    });
  };

  const handleAddToCart = async (sellerId: any) => {
    setAddingToCart(true);
    if (loggedInToken && loggedInToken !== "") {
      if (custQueryDataRes && custQueryDataRes?.customerCart?.id !== "") {
        cartId = custQueryDataRes?.customerCart?.id;
        set_quest_cart_id(cartId);
      } else {
        loggedUserCart({})
          .then(async (response) => {
            if (response?.data && response?.data?.createEmptyCart) {
              cartId = response?.data?.createEmptyCart;
              set_quest_cart_id(cartId);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (cartId) {
        if (detailsData?.variants && detailsData?.variants.length > 0) {
          await handleConfigProdCart(sellerId);
        } else {
          await addSimpleCart(sellerId);
        }
        getCartRefetch();
      }
    } else {
      if (cartId && cartId !== "undefined") {
        if (detailsData?.variants && detailsData?.variants.length > 0) {
          await handleConfigProdCart(sellerId);
        } else {
          await addSimpleCart(sellerId);
        }
        getCartRefetch();
      } else {
        guestCart({})
          .then(async (response) => {
            if (
              (currentCartId === undefined && currentStore === undefined) ||
              currentStore !== store
            ) {
              if (response?.data && response?.data?.createEmptyCart) {
                cartId = response.data.createEmptyCart;
                // localStorage.setItem("quest_cart_id", cartId);
                const sessionObject = {
                  sessionGenCartId: cartId,
                  genStore: store,
                };
                storeAndCartData.push(sessionObject);
                const sessionObjectData: any = JSON.stringify(storeAndCartData);
                sessionStorage.setItem("storeAndCart", sessionObjectData);
                set_quest_cart_id(cartId);
                if (detailsData?.variants && detailsData?.variants.length > 0) {
                  await handleConfigProdCart(sellerId);
                } else {
                  await addSimpleCart(sellerId);
                }
                setTempCartId(cartId);
              } else {
                console.error("Invalid response data structure");
              }
            } else {
              set_quest_cart_id(currentCartId);
              cartId = currentCartId;
              if (detailsData?.variants && detailsData?.variants.length > 0) {
                await handleConfigProdCart(sellerId);
              } else {
                await addSimpleCart(sellerId);
              }

              setTempCartId(cartId);
            }
            getCartRefetch();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
    setAddingToCart(false);
  };

  // useEffect(() => {
  //   refetchProData();
  // }, [store, refetchProData]);

  useEffect(() => {
    const checkPrices = () => {
      const regularPrice = variantsDetails?.price_range?.minimum_price?.regular_price?.value;
      const finalPrice = variantsDetails?.price_range?.minimum_price?.final_price?.value;
      
      if (regularPrice !== undefined && finalPrice !== undefined) {
        return regularPrice !== finalPrice;
      }
      
      const detailsRegular = detailsData?.price_range?.minimum_price?.regular_price?.value;
      const detailsFinal = detailsData?.price_range?.minimum_price?.final_price?.value;
      
      if (detailsRegular !== undefined && detailsFinal !== undefined) {
        return detailsRegular !== detailsFinal;
      }
      
      return false;
    };
  
    setDisplayWasPrice(checkPrices());
  
    // eslint-disable-next-line
    let preOrderCheck = detailsData?.attributes?.map((row: any, index: any) =>
      row?.code === "pre_order_delivery_date" ? setPreOrder(true) : ""
    );
    // eslint-disable-next-line
    let preOrderCheckDate = detailsData?.attributes?.map(
      (row: any, index: any) =>
        row?.code === "pre_order_delivery_date"
          ? setPreOrderDeliveryDate(row?.value)
          : ""
    );
  }, [variantsDetails, detailsData]);

  if (detailsData?.variants && detailsData?.variants.length > 0) {
    // eslint-disable-next-line array-callback-return
    detailsData?.variants?.map((items: any, index: any) => {
      galleryImage.push(
        <div className="tab-box">
          <img
            src={items?.product?.media_gallery?.[index]?.thumbnail_url}
            alt={`${items?.product?.name}-${index + 1}`}
            style={{ height: "100%", width: "100%" }}
            loading="lazy"
          />
        </div>
      );
    });
  } else {
    // eslint-disable-next-line array-callback-return
    detailsData?.media_gallery?.map((items: any, index: any) => {
      galleryImage.push(
        <div className="tab-box">
          <img
            src={items?.thumbnail_url}
            alt={`${items?.product?.name}-${index + 1}`}
            loading="lazy"
          />
        </div>
      );
    });
  }

  if (detailsData?.variants && detailsData?.variants.length > 0) {
    // eslint-disable-next-line array-callback-return
    detailsData?.variants?.map((items: any, index: any) => {
      smallImage.push(
        <div className="tab-box">
          <img
            src={items?.product?.small_image?.url}
            alt={items?.product?.small_image?.label}
            style={{ height: "100%", width: "100%" }}
            loading="lazy"
          />
        </div>
      );
    });
  } else {
    smallImage = (
      <div className="tab-box">
        <img
          className="img-cover"
          src={detailsData?.small_image?.url}
          alt={detailsData?.small_image?.label}
          loading="lazy"
        />
      </div>
    );
  }

  useEffect(() => {
    const sessionStoreDataVal = sessionStorage.getItem("storeAndCart");
    if (sessionStoreDataVal) {
      try {
        const storeAndCartArray = JSON.parse(sessionStoreDataVal);
        if (Array.isArray(storeAndCartArray) && storeAndCartArray.length > 0) {
          const filteredArray = storeAndCartArray?.filter(
            (object) => object.genStore === store
          );
          filteredArray?.forEach((item) => {
            setCurrentStore(item.genStore);
            setCurrentCartId(item.sessionGenCartId);
          });
        } else {
          console.error("sessionStoreDataVal is not an array or is empty");
        }
      } catch (error) {
        console.error("Error parsing sessionStoreDataVal:", error);
      }
    } else {
      console.log("sessionStoreDataVal is null or undefined");
    }
  }, [cartId, store]);

  useMemo(() => {
    if (
      cartDetailsRes &&
      cartDetailsRes?.cart &&
      cartDetailsRes?.cart?.items?.length
    ) {
      setCartCount(cartDetailsRes?.cart?.items?.length);
      set_quest_cart_id(tempCartId);
      setCartData(cartDetailsRes?.cart?.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartDetailsRes]);

  useEffect(() => {
    set_quest_cart_id(cartId);
    // localStorage.setItem("quest_cart_id", cartId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custQueryDataRes]);

  const reviewsArr = detailsData?.reviews?.items;

  const ratingsOutOf5: number[] =
    detailsData?.reviews?.items.length &&
    reviewsArr.map((review: any) => review.average_rating / 20);
  const averageRating: number =
    detailsData?.reviews?.items.length &&
    ratingsOutOf5.reduce((total: number, rating: number) => total + rating, 0) /
      ratingsOutOf5.length;
  const finalAverageRating: number = Math.round(averageRating * 10) / 10;

  useEffect(() => {
    // refetchProData();
    store.match("ar")
      ? setPreOrderImg(PreOrderImageAR)
      : setPreOrderImg(PreOrderImageEN);
  }, [store]);

  const handleClose = () => {
    setCartModalOpen(false);
    setAddedToCart(false);
    setProQty("1");
    setShowCustomInput(false);
  };

  const handleViewdetails = () => {
    navigate(`/${store}/${urlkey}.html`);
    setCartModalOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            border: "none !important",
          },
        },
      }}
      sx={{
        backgroundColor: "transparent !important",
        border: "none !important",
        boxShadow: "none !important",
      }}
    >
      <Box
        className="add-to-card-modal-box"
        sx={{
          maxWidth: "837px",
          width: "90%",
          bgcolor: "background.paper",
          borderRadius: 2,
          border: "none !important",
          boxShadow: "none !important",
          p: 4,
          mx: "auto",
          mt: "5%",
          height: { md: "76.5vh", xs: "85vh" },
          maxHeight: { md: "80vh", xs: "89vh" },
          overflowY: "auto",
          position: "relative",
          padding: "30px 35px 0px 20px",
          "&::-webkit-scrollbar": {
            width: showAll ? "5px" : "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "10px",
          },
        }}
      >
        {/* {detailsloader ? (
          <DetailsPageSkelton propsDetails="detailsModalPage"/>
        ) : ( */}
        <Box className="directionrtl">
          {/* Close Button */}
          <IconButton
            onClick={handleClose}
            sx={{
              zIndex: "9999",
              position: "absolute",
              top: 16,
              right:
                store === "ae_ar" || store === "ar" || store === "sa_ar"
                  ? "auto"
                  : 16,
              left:
                store === "ae_ar" || store === "ar" || store === "sa_ar"
                  ? 16
                  : "auto",
            }}
          >
            <CloseIcon onClick={handleClose} />
          </IconButton>

          {/* Main Grid */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  flexGrow: 1,
                  bgcolor: "background.paper",
                  display: "flex",
                  flexDirection: "column",
                  // height: 330,
                }}
              >
                <Box className="right-img-tab cust-tabs-list-Is">
                  {!isMobileScreen ? (
                    <Box
                      className="cust-tabs-view-img"
                      sx={{
                        flexGrow: 1,
                        bgcolor: "background.paper",
                        display: "flex",
                        height: 330,
                      }}
                    >
                      {detailsData?.variants &&
                      detailsData?.variants?.length > 0 ? (
                        <>
                          <Tabs
                            sx={{
                              width: "140px",
                              // height: "140px",
                              overflow: "auto",
                              ".MuiTabs-scrollButtons:first-of-type": {
                                display: "none",
                              },
                            }}
                            className="right-tabs-Is modal-right-tabs"
                            orientation="vertical"
                            variant="scrollable"
                            value={rvalue}
                            // onChange={handleChange}
                            aria-label="Media gallery tabs "
                            // sx={{ borderRight: 1, borderColor: 'divider' }}
                          >
                            {detailsData?.variants[
                              lvalue
                            ]?.product?.media_gallery?.map(
                              (items: any, index: any) => {
                                return (
                                  <Tab
                                    {...a11yProps(index)}
                                    key={index}
                                    label={
                                      <div className="tab-box">
                                        <img
                                          src={items?.thumbnail_url}
                                          alt={`${detailsData?.name}-${
                                            index + 1
                                          }`}
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                          }}
                                          loading="lazy"
                                        />
                                      </div>
                                    }
                                    onMouseEnter={(e) => handleChange(e, index)}
                                  />
                                );
                              }
                            )}
                          </Tabs>

                          <TabPanel
                            value={rvalue}
                            index={rvalue}
                            detailsData={detailsData}
                          >
                            <ReactImageMagnify
                              {...{
                                className: "-magnifyimg",
                                smallImage: {
                                  alt: `${detailsData?.variants[lvalue]?.product?.name}`,
                                  isFluidWidth: true,
                                  // width: 300,
                                  src: `${detailsData?.variants[lvalue]?.product?.media_gallery[rvalue]?.url}`
                                    ? `${detailsData?.variants[lvalue]?.product?.media_gallery[rvalue]?.url}`
                                    : ``,
                                  srcSet:
                                    `${detailsData?.variants[lvalue]?.product?.media_gallery[rvalue]?.url}`
                                      ? `${detailsData?.variants[lvalue]?.product?.media_gallery[rvalue]?.url}`
                                      : ``,
                                  sizes:
                                    "(min-width: 800px) 33.5vw, (min-width: 415px) 50vw, 100vw",
                                },
                                largeImage: {
                                  alt: `${detailsData?.variants[lvalue]?.product?.name}`,
                                  src: `${detailsData?.variants[lvalue]?.product?.media_gallery[rvalue]?.url}`,
                                  width: 800,
                                  height: 600,
                                },
                                enlargedImageContainerStyle: {
                                  zIndex: "1500",
                                  backgroundColor: "white",
                                },
                                enlargedImageContainerDimensions: {
                                  width: "100%",
                                  height: "100%",
                                },
                                enlargedImageContainerClassName:
                                  "custom-enlarged-container",
                                isHintEnabled: true,
                              }}
                            />
                          </TabPanel>
                        </>
                      ) : (
                        <>
                          <Tabs
                            sx={{
                              width: "140px",
                              overflow: "auto",
                              ".MuiTabs-scrollButtons:first-of-type": {
                                display: "none",
                              },
                            }}
                            className="right-tabs-Is"
                            orientation="vertical"
                            variant="scrollable"
                            value={rvalue}
                            onChange={handleChange}
                            aria-label="Media gallery tabs "
                            // sx={{ borderRight: 1, borderColor: 'divider' }}
                          >
                            {[
                              ...Array(detailsData?.media_gallery?.length),
                            ]?.map((items, index) => (
                              <Tab
                                {...a11yProps(
                                  detailsData?.media_gallery[index]
                                )}
                                key={index}
                                label={galleryImage[index]}
                                onMouseEnter={(e) => handleChange(e, index)}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                }}
                              />
                            ))}
                          </Tabs>
                          <TabPanel
                            value={rvalue}
                            index={rvalue}
                            detailsData={detailsData}
                          >
                            <ReactImageMagnify
                              {...{
                                className: "-magnifyimg",
                                smallImage: {
                                  alt: `${detailsData?.name}`,
                                  isFluidWidth: true,
                                  // width: 300,
                                  src: `${detailsData?.media_gallery[rvalue]?.url}`,
                                  srcSet: `${detailsData?.media_gallery[rvalue]?.url}`,
                                  sizes:
                                    "(min-width: 800px) 33.5vw, (min-width: 415px) 50vw, 100vw",
                                },
                                largeImage: {
                                  alt: `${detailsData?.name}`,
                                  src: `${detailsData?.media_gallery[rvalue]?.url}`,
                                  width: 500,
                                  height: 300,
                                },
                                enlargedImageContainerStyle: {
                                  zIndex: "1500",
                                  backgroundColor: "white",
                                },
                                enlargedImageContainerDimensions: {
                                  width: "60%",
                                  height: "60%",
                                },
                                isHintEnabled: true,
                              }}
                            />
                          </TabPanel>
                        </>
                      )}
                    </Box>
                  ) : (
                    <Box>
                      <Carousel
                        responsive={detailsSlider}
                        showDots={true}
                        swipeable={true}
                        infinite={true}
                        autoPlay={detailsData?.media_gallery?.length > 1}
                        slidesToSlide={1}
                        transitionDuration={500}
                        autoPlaySpeed={5000}
                        customTransition="all 3s linear"
                        removeArrowOnDeviceType={["mobileSlider"]}
                        containerClass="mobile-carousel-container"
                      >
                        {detailsData?.variants?.length > 0
                          ? detailsData?.variants[
                              lvalue
                            ]?.product?.media_gallery?.map(
                              (item: any, index: number) => (
                                <Card
                                  className="popup-slider-card"
                                  key={index}
                                  sx={{
                                    boxShadow: "none",
                                    position: "relative",
                                  }}
                                >
                                  {/* Badge Image */}
                                  {detailsData?.top_badge_left && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        left: "0px",
                                        zIndex: 10,
                                      }}
                                    >
                                      <img
                                        src={detailsData?.top_badge_left}
                                        alt={
                                          detailsData?.top_badge_left_title ||
                                          "Badge"
                                        }
                                        style={{
                                          width: "120px",
                                          height: "auto",
                                          marginTop: "-37px",
                                        }}
                                        loading="lazy"
                                      />
                                    </div>
                                  )}

                                  {/* Main Image */}
                                  {/* eslint-disable-next-line */}
                                  <img
                                    src={item?.url}
                                    alt={`Variant Image ${index + 1}`}
                                    style={{
                                      width: "100%",
                                      // height: "100%",
                                      maxWidth: "295px",
                                    }}
                                    loading="lazy"
                                  />
                                </Card>
                              )
                            )
                          : detailsData?.media_gallery?.map(
                              (item: any, index: number) => (
                                <Card
                                  className="popup-slider-card"
                                  key={index}
                                  sx={{
                                    boxShadow: "none",
                                    position: "relative",
                                  }}
                                >
                                  {/* Badge Image */}
                                  {detailsData?.top_badge_left && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        left: "0px",
                                        zIndex: 10,
                                      }}
                                    >
                                      <img
                                        src={detailsData?.top_badge_left}
                                        alt={
                                          detailsData?.top_badge_left_title ||
                                          "Badge"
                                        }
                                        style={{
                                          width: "120px",
                                          height: "auto",
                                          marginTop: "-37px",
                                        }}
                                        loading="lazy"
                                      />
                                    </div>
                                  )}

                                  {/* Main Image */}
                                  {/* eslint-disable-next-line */}
                                  <img
                                    src={item?.url}
                                    alt={`Image ${index + 1}`}
                                    style={{
                                      // width: "100%",
                                      height: "100%",
                                      maxHeight: "295px",
                                    }}
                                    loading="lazy"
                                  />
                                </Card>
                              )
                            )}
                      </Carousel>
                    </Box>
                  )}
                </Box>
                <Box
                  className="specification-typo"
                  sx={{
                    padding: "20px 20px 0px 20px",
                  }}
                >
                  <Typography
                    variant="body1"
                    gutterBottom
                    className="spec-head"
                  >
                    <strong>
                      {" "}
                      {t("inner_pages.detail_page.specification")}:
                    </strong>
                  </Typography>

                  {/* Scrollable Grid for View More */}
                  <Box>
                    <Grid container spacing={1}>
                      {/* Render Rows Dynamically */}
                      {displayedData?.map((row: any, index: any) => (
                        <React.Fragment key={index}>
                          <Grid item xs={6}>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 500 }}
                            >
                              {row.label}:
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">{row.value}</Typography>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Box>

                  {/* View More/Collapse Link */}
                  {detailsData?.attributes?.length > 4 && (
                    <Link
                      to="#"
                      className="view-more-txt"
                      onClick={handleViewMore}
                      rel="preload"
                    >
                      <p>
                        {showAll
                          ? t("inner_pages.detail_page.view_less")
                          : t("inner_pages.detail_page.view_more")}
                      </p>
                    </Link>
                  )}
                </Box>
              </Box>
            </Grid>
            {/* Right Side: Product Details */}
            <Grid item xs={12} md={6} className="right-side-content">
              <Box
                sx={{ textAlign: "start" }}
                className="details-center-content"
              >
                <div className="dtop-img" style={{ maxHeight: "70px" }}>
                  {detailsData?.brand_logo_img && ""}
                  {preOrder ? (
                    // eslint-disable-next-line
                    <img
                      src={preOrderImg}
                      alt="Image not found"
                      style={{
                        width: "150px",
                        display: "flex",
                        float: store.match("ar") ? "left" : "right",
                      }}
                      loading="lazy"
                    />
                  ) : (
                    ""
                  )}
                </div>
                {/* {detailsData?.variants.length > 0 ? */}
                <Stack spacing={2} direction="column">
                  <Link
                    to={`/${store}/${detailsData?.brand_logo_link}`}
                    rel="preload"
                  >
                    <img
                      // style={{ maxHeight: "25px" }}
                      src={detailsData?.brand_logo_img}
                      alt={detailsData?.name}
                      loading="lazy"
                      style={{ maxWidth: "32px" }}
                    />
                  </Link>
                  {/* <Link
                    to={`/${store}/${urlkey}.html`}
                    rel="preload"
                  > */}
                  <Typography
                    variant="h3"
                    component="h1"
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#000",
                      marginTop: "0px",
                      cursor: "pointer",
                    }}
                    onClick={handleViewdetails}
                  >
                    {variantsDetails && variantsDetails !== undefined
                      ? variantsDetails?.name
                      : detailsData?.name}
                  </Typography>
                  <div className="rating-box">
                    <Chip
                      // label={detailsData?.rating_summary}
                      label={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                            // gap:"5px"
                          }}
                        >
                          <StarOutlinedIcon
                            className="starIcon"
                            style={{
                              // marginLeft: "4px",
                              width: "20px",
                            }}
                          />
                          {detailsData?.reviews?.items.length
                            ? finalAverageRating
                            : 0}
                        </div>
                      }
                      color="success"
                    />
                    <span className="total-rating">
                      {detailsData?.review_count}{" "}
                      {t("inner_pages.detail_page.ratings")}
                    </span>
                  </div>
                  <div className="amount-box">
                    {displayWasPrice && (
                      <div className="amount-was">
                        <label>{t("inner_pages.detail_page.was")}</label>

                        <label>
                          {variantsDetails && variantsDetails !== undefined ? (
                            <>
                              {
                                variantsDetails?.price_range?.minimum_price
                                  ?.regular_price?.currency
                              }{" "}
                              {
                                variantsDetails?.price_range?.minimum_price
                                  ?.regular_price?.value
                              }
                            </>
                          ) : (
                            <>
                              {
                                detailsData?.price_range?.minimum_price
                                  ?.regular_price?.currency
                              }{" "}
                              {
                                detailsData?.price_range?.minimum_price
                                  ?.regular_price?.value
                              }
                            </>
                          )}
                        </label>

                        {AmountOff > 0 && (
                          <Typography className="percentage-offIs">
                            {AmountOffFormatted} %{" "}
                            {t("inner_pages.domestic_machine.percentage_off")}
                          </Typography>
                        )}
                      </div>
                    )}
                    <div className="amount-now">
                      <label>{t("inner_pages.detail_page.now")}</label>
                      <Typography variant="h4" component="h4">
                        {variantsDetails && variantsDetails !== undefined ? (
                          <>
                            {
                              variantsDetails?.price_range?.minimum_price
                                ?.final_price?.currency
                            }{" "}
                            {
                              variantsDetails?.price_range?.minimum_price
                                ?.final_price?.value
                            }
                          </>
                        ) : (
                          <>
                            {
                              detailsData?.price_range?.minimum_price
                                ?.final_price?.currency
                            }{" "}
                            {
                              detailsData?.price_range?.minimum_price
                                ?.final_price?.value
                            }
                          </>
                        )}
                        &nbsp;
                        <span
                          style={{
                            fontSize: "14px",
                            color: "#3D3D3D",
                            fontWeight: "400",
                          }}
                        >
                          {country === "in"
                            ? t("main.inclusive_of_gst")
                            : t("main.inclusive_of_vat")}
                        </span>
                      </Typography>
                    </div>

                    <div
                      className="stock-msg"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "4px",
                      }}
                    >
                      {preOrder ? (
                        <>
                          <label>
                            {t("inner_pages.detail_page.pre-order")}
                          </label>

                          <div className="delivr-status-msg">
                            <label
                              style={{
                                float: store.match("ar") ? "right" : "left",
                              }}
                            >
                              {t("inner_pages.detail_page.delivery_estimation")}{" "}
                              : <span>{preOrderDeliveryDate}</span>
                            </label>
                            <span
                              style={{
                                backgroundImage: `url(${PlusMinusImage})`,
                                width: "135px",
                                height: "55px",
                                display: "flex",
                                float: store.match("ar") ? "right" : "left",
                                backgroundSize: "95px",
                                backgroundRepeat: "no-repeat",
                                marginTop: "-5px",
                              }}
                            ></span>
                          </div>
                        </>
                      ) : (
                        <>
                          <label
                            className={`stock_label ${
                              detailsData?.stock_status === "IN_STOCK"
                                ? "in_stock"
                                : "out_of_stock"
                            }`}
                          >
                            {detailsData?.stock_status === "IN_STOCK"
                              ? t("inner_pages.detail_page.instock")
                              : t("inner_pages.detail_page.outStock")}
                          </label>
                          <div className="delivr-status-msg">
                            <label>
                              {t("inner_pages.detail_page.delivery_estimation")}{" "}
                              :{" "}
                            </label>
                            <span>{detailsData?.delivery_estimation}</span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {groupedVariants &&
                    Object.keys(groupedVariants).length > 0 && (
                      <div className="product-selector">
                        <div className="bag-size-selector">
                          <label>
                            {t("inner_pages.detail_page.bag_size")}{" "}
                            <span>:</span> {selectedBagSize}
                          </label>
                          <div className="bag-sizes">
                            {bagSizes?.map((size, index) => (
                              <button
                                key={index}
                                className={`bag-size-button ${
                                  size === selectedBagSize ? "active" : ""
                                }`}
                                onClick={() => handleBagSizeClick(size)}
                              >
                                {size}
                              </button>
                            ))}
                          </div>
                        </div>
                        <div className="grinder-type-selector">
                          <label>
                            {t("inner_pages.detail_page.grinder_type")}
                          </label>
                          <FormControl fullWidth variant="outlined">
                            <FormControl variant="outlined">
                              <Select
                                id="grinder-select"
                                value={selectedGrinder}
                                onChange={handleGrinderChange}
                                displayEmpty
                                sx={{
                                  width: "50%",
                                  fontSize: "14px",
                                  color: "#333",
                                  padding: "8px",
                                  borderRadius: "1px",

                                  "& .MuiSelect-select": {
                                    padding: "8px 12px",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#EEEEEE",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#EEEEEE",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#555",
                                    },
                                }}
                              >
                                {Object.keys(groupedVariants).map(
                                  (grinderType) => (
                                    <MenuItem
                                      key={grinderType}
                                      value={grinderType}
                                      sx={{
                                        fontSize: "14px",
                                        color: "#5F5E5E",
                                        "&:hover": {
                                          backgroundColor: "#f0f0f0",
                                        },
                                        direction: store.match("ar")
                                          ? "rtl"
                                          : "ltr",
                                        textAlign: store.match("ar")
                                          ? "right"
                                          : "left",
                                      }}
                                    >
                                      {grinderType}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </FormControl>
                        </div>
                      </div>
                    )}

                  <label>{t("inner_pages.detail_page.qty")}</label>
                  <div className="qty-box is-mobile-fixed-Is">
                    <Box
                      sx={{
                        minWidth: "100%",
                        display: "flex",
                        gap: "12px",
                        alignItems: "end",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          width: 120,
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                          "@media screen and (max-width: 600px)": {
                            width: 100,
                          },
                        }}
                      >
                        <FormControl
                          fullWidth
                          sx={{
                            border: "1px solid #C6C3C3",
                            borderRadius: "2px",
                          }}
                        >
                          {" "}
                          {showCustomInput ? (
                            <Input
                              className="quantity-input-filed"
                              type="number"
                              value={proQty}
                              onChange={(e: any) => setProQty(e.target.value)}
                              // placeholder="Enter custom quantity"
                            />
                          ) : (
                            <Select
                              IconComponent={CustomSelectIcon}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className="qty-select"
                              value={
                                detailsData?.stock_status === "OUT_OF_STOCK"
                                  ? "0"
                                  : proQty
                              }
                              label="Quantity"
                              onChange={handledQtyChange}
                              input={<Input />}
                            >
                              {qtyOptions.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </FormControl>
                      </Box>
                      <Button
                        className="add-to-cart-btn"
                        onClick={() => {
                          if (
                            sellerData &&
                            sellerData?.sellerData &&
                            sellerData.sellerData.length > 0
                          ) {
                            handleAddToCart(
                              sellerData?.sellerData[0]?.seller_id
                            );
                          } else {
                            handleAddToCart(0);
                          }
                        }}
                        variant="contained"
                        startIcon={
                          <LocalMallOutlinedIcon
                            sx={{
                              float: store.match("ar") ? "left" : "right",
                              marginLeft: "10px",
                              display: {
                                xs: "none",
                                sm: "block",
                              },
                            }}
                          />
                        }
                        size="large"
                        disabled={
                          detailsData?.stock_status === "OUT_OF_STOCK"
                            ? true
                            : false
                        }
                        sx={{
                          textTransform: "capitalize",
                          color: "#fff",
                          fontSize: "18px",
                          fontWeight: "500",
                          padding: "6px 34px",
                          width: "100%",
                        }}
                      >
                        {preOrder
                          ? addingToCart
                            ? t("inner_pages.detail_page.adding")
                            : addedToCart
                            ? t("inner_pages.detail_page.added")
                            : t("inner_pages.detail_page.pre-order")
                          : addingToCart
                          ? t("inner_pages.detail_page.adding")
                          : addedToCart
                          ? t("inner_pages.detail_page.added")
                          : t("inner_pages.detail_page.add_to_cart")}
                      </Button>
                      {/* <Addcart isOpenPopup={openPopup} isAnchor={anchor} handleClosePopup={handleClosePopup}/> */}

                      <IconButton
                        aria-label="delete"
                        size="small"
                        sx={{
                          borderRadius: "5px",
                          border: "1px solid #E7E5E5",
                          background: "#F8F4F4",
                          p: 1,
                        }}
                      >
                        <div style={{ cursor: "pointer" }}>
                          {isAdded ? (
                            <FavoriteIcon
                              fontSize="medium"
                              style={{ color: "red" }}
                              onClick={handleRemoveWishlistClick}
                            />
                          ) : (
                            <FavoriteBorderOutlinedIcon
                              fontSize="medium"
                              onClick={handleWishlistClick}
                            />
                          )}
                        </div>
                      </IconButton>
                    </Box>
                  </div>
                  <div className="add-cart-grp"></div>
                  {detailsData?.variants &&
                  detailsData?.variants.length > 0 &&
                  Object.keys(groupedVariants).length === 0 ? (
                    <div className="color-name-block">
                      <label>
                        {lableVariation && lableVariation !== undefined
                          ? lableVariation.charAt(0).toUpperCase() +
                            lableVariation.slice(1)
                          : ""}{" "}
                      </label>
                      :{" "}
                      <span>
                        {colorVariation && colorVariation !== undefined
                          ? colorVariation
                          : ""}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {detailsData?.variants &&
                  detailsData?.variants.length > 0 &&
                  Object.keys(groupedVariants).length === 0 ? (
                    <div className="custom-img-tab cust-tabs-list-Is">
                      <Box>
                        <Tabs
                          value={lvalue}
                          onChange={handleChangeTab}
                          aria-label="image tabs"
                          variant="scrollable"
                          scrollButtons="auto"
                          allowScrollButtonsMobile
                          sx={{
                            overflow: "auto",
                            ".MuiTabs-scrollButtons:first-of-type": {
                              cursor: lvalue === 1 ? "not-allowed" : "pointer",
                              opacity: lvalue === 1 ? 0.01 : 1,
                            },
                            ".MuiTabs-scrollButtons:last-of-type": {
                              cursor:
                                lvalue === detailsData?.variants?.length - 1
                                  ? "not-allowed"
                                  : "pointer",
                              opacity:
                                lvalue === detailsData?.variants?.length - 1
                                  ? 0.01
                                  : 1,
                            },
                          }}
                        >
                          {Array.from(Array(detailsData?.variants?.length)).map(
                            (_, index) => (
                              <Tab
                                label={smallImage[index]}
                                {...a11yProps(index)}
                                key={index}
                                sx={{ p: 1 }}
                              />
                            )
                          )}
                        </Tabs>
                      </Box>
                    </div>
                  ) : (
                    ""
                  )}
                  {(store === "ae_en" ||
                    store === "ae_ar" ||
                    store === "sa_en" ||
                    store === "sa_ar") &&
                  detailsData?.price_range?.minimum_price?.final_price?.value <=
                    5000 ? (
                    <TabbyProductWidget
                      amt={
                        variantsDetails && variantsDetails !== undefined
                          ? variantsDetails?.price_range?.minimum_price
                              ?.regular_price?.value
                          : detailsData?.price_range?.minimum_price?.final_price
                              ?.value
                      }
                      currency={
                        detailsData?.price_range?.minimum_price?.final_price
                          ?.currency
                      }
                      store={store}
                      page="detail"
                    />
                  ) : (
                    ""
                  )}
                  <Typography
                    variant="body2"
                    component="ul"
                    className="prod-description"
                    sx={{ px: 0 }}
                  >
                    <div
                      className={`description-text ${
                        expanded ? "expanded" : ""
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                    {description && description.length > 4 * 90 ? (
                      <span onClick={toggleExpanded} className="view-more-txt">
                        {expanded
                          ? `${t("inner_pages.detail_page.read_less")}`
                          : `${t("inner_pages.detail_page.read_more")}`}
                      </span>
                    ) : (
                      ""
                    )}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* )} */}
      </Box>
    </Modal>
  );
};

export default React.memo(ProductModal);
